<template>
  <v-content>
    <dashboard-core-app-header />
    <v-fade-transition>
      <router-view />
    </v-fade-transition>

    <dashboard-core-footer />
  </v-content>
</template>

<script>
  export default {
    name: 'DashboardCoreView',

    components: {
      DashboardCoreAppHeader: () => import('./AppHeader'),
      DashboardCoreFooter: () => import('./Footer'),
    },

    computed: {
      hideLayout () {
        return this.$route.query.hideLayout === 'yes'
      },
    },

    created () {
      // pas
    },
  }
</script>
